@import '~antd/dist/antd.less';

@font-face {
  font-family: 'KONE Information';
  src: local('KONE Information'), url(./fonts/KONE-Information.ttf) format('truetype');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@primary-color: #0071B9;@link-color: #0C9FED;@disabled-bg: #566066;@btn-disable-border: #566066;@disabled-color: #AAAFB3;@font-family: 'KONE Information', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
              'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 16px;@body-background: #11161a;@layout-body-background: #11161a;@layout-footer-background: @layout-body-background;@auth-page-font-color: #fff;@light-blue: #4dacff;@error-message: #ed4337;@border-radius-base: 4px;